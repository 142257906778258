import React from "react";
// import { Link } from "gatsby";

import * as styles from "./sub-header.module.css";

const SubHeader = () => (
  <div className={styles.subHeader}>
    <img src="/subheader.svg" alt="illustration" width="260" />
    <div className={styles.subHeaderText}>
      <h1>Hello!</h1>
      <h2 className={styles.subHeaderSubTitle}>
        I am <a href="/about">Johnny</a>,<br />
        a designer/
        <br />
        UX-designer
        <br /> based in Oslo.
      </h2>
    </div>
  </div>
);

export default SubHeader;
