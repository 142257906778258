// import { Link } from "gatsby";
import React from "react";
import { cn, buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";

import * as styles from "./project-preview.module.css";
import { responsiveTitle3 } from "./typography.module.css";

function ProjectPreview(props) {
  return (
    <div>
      <div className={styles.leadMediaThumb}>
        {props.mainImage && props.mainImage.asset && (
          <a className={styles.root} href={`/work/${props.slug.current}`}>
            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width(1800)
                .height(Math.floor((3 / 4) * 1800))
                .fit("crop")
                .url()}
              alt={props.mainImage.alt}
              className={styles.image}
            />
          </a>
        )}
      </div>
      {props.isInList && (
        <div className={styles.flexWrapper}>
          <h1 className={styles.title}>{props.title}</h1>
          <a
            className={styles.root}
            href={`/work/${props.slug.current}`}
            aria-label={`View ${props.title}`}
          >
            <span className={styles.button}>view</span>
          </a>
        </div>
      )}
      {!props.isInList && (
        <h2 className={cn(responsiveTitle3, styles.title)}>{props.title}</h2>
      )}
      {!props.isInList && props._rawExcerpt && (
        <div className={styles.excerpt}>
          <BlockContent blocks={props._rawExcerpt} />
        </div>
      )}
      {!props.isInList && (
        <a
          className={styles.buttonInList}
          href={`/work/${props.slug.current}`}
          aria-label={`View ${props.title}`}
        >
          <span className={styles.button}>view</span>
        </a>
      )}
    </div>
  );
}

export default ProjectPreview;
