import * as styles from "./project-preview-list.module.css";
import ProjectPreview from "./project-preview";
// import { Link } from "gatsby";
import React from "react";

function ProjectPreviewList(props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node) => (
            <li key={node.id} className={styles.gridItem}>
              <ProjectPreview {...node} isInList />
            </li>
          ))}
      </ul>

      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <a href={props.browseMoreHref}>view all work</a>
        </div>
      )}
    </div>
  );
}

ProjectPreviewList.defaultProps = {
  nodes: [],
  browseMoreHref: "",
};

export default ProjectPreviewList;
